.screen_loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}
.loading__icon {
	color: inherit;
	animation: rotate 1s ease-in-out infinite;
	font-size: 35px;
}
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
