@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-style: normal;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-style: normal;
}
body {
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(99, 94, 94);
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border-left: 6.5px solid transparent;
    border-right: 6.5px solid transparent;
    background: transparent;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}
